import { PRICE_TRACK_LIST } from "store/types.js";
import { addError, removeError } from "store/actions/error.js";
import { setCurrentUser } from "store/actions/auth";
import { setLoading } from "store/actions/loading.js";
import { call } from "services/api.js";

export const setPriceTrackList = (priceTracks) => ({
  type: PRICE_TRACK_LIST,
  priceTracks,
});

export const getPriceTracks = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const response = await call("get", "price-tracks");
      const priceTracks = response.data;

      dispatch(setPriceTrackList(priceTracks));
      dispatch(removeError());
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setCurrentUser({}));
        localStorage.removeItem("jwtToken");
        dispatch(addError(error.response.data.data));
      } else {
        dispatch(addError(error.response.data));
      }
    }
    dispatch(setLoading());
  };
};
