import { PRICE_TRACK_LIST } from "store/types.js";

const initialState = {
  priceTracks: [],
};

const item = (state = initialState, action) => {
  switch (action.type) {
    case PRICE_TRACK_LIST:
      return {
        ...state,
        priceTracks: action.priceTracks,
      };
    default:
      return state;
  }
};

export default item;
