import { SHOP_LIST } from "store/types.js";

const initialState = {
  shops: [],
};

const shop = (state = initialState, action) => {
  switch (action.type) {
    case SHOP_LIST:
      return {
        ...state,
        shops: action.shops,
      };
    default:
      return state;
  }
};

export default shop;
