import React from "react";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";


function Dashboard() {
  return (
    <>
      <CardsHeader name="EPOS" parentName="Dashboards" />
    </>
  );
}

export default Dashboard;
