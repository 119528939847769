import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { connect } from "react-redux";

const { SearchBar } = Search;

const SingleSales = ({ singleSale }) => {
  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file

  return (
    <>
      <SimpleHeader name="Single Sale List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Your's Single Sale List</h3>
                <p className="text-sm mb-0">Single Sale</p>
              </CardHeader>
              <ToolkitProvider
                data={singleSale.singleSales}
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "ID",
                    sort: true,
                  },
                  {
                    dataField: "item.name",
                    text: "Item Name",
                    sort: true,
                  },
                  {
                    dataField: "item_price",
                    text: "Item Price",
                    sort: true,
                  },
                  {
                    dataField: "quantity",
                    text: "Quantity",
                    sort: true,
                  },
                  {
                    dataField: "total_price",
                    text: "Total Price",
                    sort: true,
                  },
                  {
                    dataField: "created_at",
                    text: "Created At",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  singleSale: store.singleSale,
});

export default connect(mapStateToProps)(SingleSales);
