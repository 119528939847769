import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AuthRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !auth.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/dashboard" />
        )
      }
    />
  );
}

const mapStateToProps = (store) => ({
  auth: store.auth,
});

export default connect(mapStateToProps)(AuthRoute);
