import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import { Card, CardHeader, Container, Row, Col, Input } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { connect } from "react-redux";
import { getPriceTracks, getShops } from "store/actions";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const PriceTracks = ({ getPriceTracks, priceTrack, getShops, shop }) => {
  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const [filterPriceTracks, setFilterPriceTracks] = React.useState(
    priceTrack.priceTracks
  );

  React.useEffect(() => {
    const fetchData = async () => {
      await getPriceTracks();
      await getShops();
    };
    fetchData();
    return () => {
      fetchData();
    };
  }, [getPriceTracks, getShops]);

  const handleSelectShop = (shopID) => {
    const filterPriceTracksByShopID = priceTrack.priceTracks.filter(
      (priceTrack) => priceTrack.shop.id === parseInt(shopID)
    );
    setFilterPriceTracks(filterPriceTracksByShopID);
  };

  return (
    <>
      <SimpleHeader name="Price Track List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Your's Price Track List</h3>
                <p className="text-sm mb-0">Price Tracks</p>
              </CardHeader>
              <ToolkitProvider
                data={
                  filterPriceTracks.length === 0
                    ? priceTrack.priceTracks
                    : filterPriceTracks
                }
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "ID",
                    sort: true,
                  },
                  {
                    dataField: "shop.name",
                    text: "Shop",
                    sort: true,
                  },
                  {
                    dataField: "item.name",
                    text: "Name",
                    sort: true,
                  },
                  {
                    dataField: "buy_price",
                    text: "Buy Price",
                    sort: true,
                  },
                  {
                    dataField: "sale_price",
                    text: "Sale Price",
                    sort: true,
                  },
                  {
                    dataField: "created_at",
                    text: "Created At",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={12} sm={6}>
                          <div className="custom-control-inline">
                            <label
                              htmlFor="shops"
                              style={{ marginRight: "5px", marginTop: "3px" }}
                            >
                              Shops:{" "}
                            </label>
                            <Input
                              id="shops"
                              type="select"
                              className="form-control-sm"
                              onChange={(event) =>
                                handleSelectShop(event.target.value)
                              }
                            >
                              <option value="all">All</option>
                              {shop.shops.map((data) => (
                                <option value={data.id} key={data.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  priceTrack: store.priceTrack,
  shop: store.shop,
});

export default connect(mapStateToProps, { getPriceTracks, getShops })(
  PriceTracks
);
