import AccountRecs from "pages/dashboard/account-rec/AccountRecs";
import Customers from "pages/dashboard/customer/Customers";
import Dashboard from "pages/dashboard/Dashboard";
import ItemTransfers from "pages/dashboard/item-transfer/ItemTransfers";
import Items from "pages/dashboard/item/Items";
import PriceTracks from "pages/dashboard/price-track/PriceTracks";
import SaleRecords from "pages/dashboard/sale-record/SaleRecords";
import Shops from "pages/dashboard/shop/Shops";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/shops",
    name: "Shops",
    icon: "ni ni-archive-2 text-green",
    component: Shops,
    layout: "/admin",
  },

  {
    path: "/items",
    name: "Items",
    icon: "ni ni-collection text-red",
    component: Items,
    layout: "/admin",
  },

  {
    path: "/price-tracks",
    name: "Price Tracks",
    icon: "ni ni-single-copy-04 text-pink",
    component: PriceTracks,
    layout: "/admin",
  },

  {
    path: "/sale-records",
    name: "Sale Records",
    icon: "ni ni-ui-04 text-info",
    component: SaleRecords,
    layout: "/admin",
  },

  {
    path: "/account-recs",
    name: "Account Recs",
    icon: "ni ni-map-big text-primary",
    component: AccountRecs,
    layout: "/admin",
  },

  {
    path: "/item-transfers",
    name: "Item Transfers",
    icon: "ni ni-align-left-2 text-default",
    component: ItemTransfers,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-chart-pie-35 text-info",
    component: Customers,
    layout: "/admin",
  },
];

export default routes;
