import React from "react";
import ReactDOM from "react-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import { Provider } from "react-redux";
import store from "store";
import decode from "jwt-decode";
import App from "App.js";

import { setCurrentUser, addError, setToken, getUser } from "store/actions";

const token = localStorage.getItem("jwtToken");

if (token) {
  setToken(token);
  try {
    store.dispatch(setCurrentUser(decode(token)));
    store.dispatch(getUser());
  } catch (error) {
    store.dispatch(setCurrentUser({}));
    store.dispatch(addError(error));
  }
}


ReactDOM.render(<Provider store={store}><App /></Provider>,
  document.getElementById("root")
);
