import { ACCOUNT_REC_LIST } from "store/types.js";

const initialState = {
  accountRecs: [],
};

const accountRec = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_REC_LIST:
      return {
        ...state,
        accountRecs: action.accountRecs,
      };
    default:
      return state;
  }
};

export default accountRec;
