import { CUSTOMER_LIST } from "store/types.js";
import { addError, removeError } from "store/actions/error.js";
import { setCurrentUser } from "store/actions/auth";
import { setLoading } from "store/actions/loading.js";
import { call } from "services/api.js";

export const setCustomerList = (customers) => ({
  type: CUSTOMER_LIST,
  customers,
});

export const getCustomers = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const response = await call("get", "customers");
      const customers = response.data;

      dispatch(setCustomerList(customers));
      dispatch(removeError());
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setCurrentUser({}));
        localStorage.removeItem("jwtToken");
        dispatch(addError(error.response.data.data));
      } else {
        dispatch(addError(error.response.data));
      }
    }
    dispatch(setLoading());
  };
};
