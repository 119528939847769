import React from "react";

// react library for routing
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import PrivateRoute from "routers/PrivateRoute";
import AuthRoute from "routers/AuthRoute";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/admin" component={AdminLayout} />
        <AuthRoute path="/auth" component={AuthLayout} />
        <Redirect from="*" to="/auth/login" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
