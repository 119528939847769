import { SET_SINGLE_SALE } from "store/types.js";
import { addError, removeError } from "store/actions/error.js";
import { setCurrentUser } from "store/actions/auth";
import { setLoading } from "store/actions/loading.js";
import { call } from "services/api.js";

export const setSingleSaleList = (singleSales) => ({
  type: SET_SINGLE_SALE,
  singleSales,
});

export const getSingleSales = (id) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const response = await call("get", `sale-record/${id}`);
      const singleSales = response.data;

      dispatch(setSingleSaleList(singleSales));
      dispatch(removeError());
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setCurrentUser({}));
        localStorage.removeItem("jwtToken");
        dispatch(addError(error.response.data.data));
      } else {
        dispatch(addError(error.response.data));
      }
    }
    dispatch(setLoading());
  };
};
