import {
  ITEM_LIST,
  CREATE_ITEM,
  STATUS_ITEM,
  IS_SUCCESS_ITEM,
  ERROR_ITEM,
  EDIT_ITEM,
  GET_ITEM,
  CLEAR_ALERT
} from "store/types.js";
import { setCurrentUser } from "store/actions/auth";
import { call } from "services/api.js";

export const clearAlert = () => ({
  type: CLEAR_ALERT,
});

export const setItemList = (items) => ({
  type: ITEM_LIST,
  items,
});

export const setItem = (item) => ({
  type: CREATE_ITEM,
  item,
});

export const setGetItem = (item) => ({
  type: GET_ITEM,
  item,
});

export const setItemStatus = (isLoading) => ({
  type: STATUS_ITEM,
  isLoading,
});

export const setItemSuccess = (isSuccess) => ({
  type: IS_SUCCESS_ITEM,
  isSuccess,
});

export const setItemError = (error) => ({
  type: ERROR_ITEM,
  error,
});

export const setEditItem = (item) => ({
  type: EDIT_ITEM,
  item
});

export const getItems = () => {
  return async (dispatch) => {
    dispatch(setItemStatus(true));
    try {
      const response = await call("get", "items");
      const items = response.data;

      dispatch(setItemList(items));
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setCurrentUser({}));
        localStorage.removeItem("jwtToken");
        dispatch(setItemError(error.response.data.data));
      } else {
        dispatch(setItemError(error.response.data));
      }
    }
    dispatch(setItemStatus(false));
  };
};

export const getItem = (id) => {
  return async (dispatch) => {
    dispatch(setItemStatus(true));
    try {
      const response = await call("get", `item/${id}`);
      const item = response.data;
      dispatch(setGetItem(item));
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setCurrentUser({}));
        localStorage.removeItem("jwtToken");
        dispatch(setItemError(error.response.data.data));
      } else {
        dispatch(setItemError(error.response.data));
      }
    }
    dispatch(setItemStatus(false));
  };
};

export const createItem = (data) => {
  return async (dispatch) => {
    dispatch(setItemStatus(true));
    try {
      const response = await call("post", "items", data);
      const item = response.data;

      dispatch(setItem(item));
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setCurrentUser({}));
        localStorage.removeItem("jwtToken");
        dispatch(setItemError(error.response.data.data));
      } else {
        dispatch(setItemError(error.response.data.data));
      }
    }
    dispatch(setItemStatus(false));
  };
};

export const editItem = (id, data) => {
  return async (dispatch) => {
    dispatch(setItemStatus(true));
    try {
      const response = await call("post", `items/${id}?_method=put`, data);
      const item = response.data;

      dispatch(setEditItem(item));
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setCurrentUser({}));
        localStorage.removeItem("jwtToken");
        dispatch(setItemError(error.response.data.data));
      } else {
        dispatch(setItemError(error.response.data.data));
      }
    }
    dispatch(setItemStatus(false));
  }
}