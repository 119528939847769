export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_LOADING = "SET_LOADING";

export const SHOP_LIST = "SHOP_LIST";

export const ITEM_LIST = "ITEM_LIST";
export const CREATE_ITEM = "CREATE_ITEM";
export const GET_ITEM = 'GET_ITEM';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const EDIT_ITEM = "EDIT_ITEM";
export const STATUS_ITEM = "STATUS_ITEM";
export const ERROR_ITEM = "ERROR_ITEM";
export const IS_SUCCESS_ITEM = "IS_SUCCESS_ITEM";

export const PRICE_TRACK_LIST = "PRICE_TRACK_LIST";

export const SALE_RECORD_LIST = "SALE_RECORD_LIST";

export const SET_SINGLE_SALE = "SET_SINGLE_SALE";

export const ACCOUNT_REC_LIST = "ACCOUNT_REC_LIST";

export const ITEM_TRANSFER_LIST = "ITEM_TRANSFER_LIST";

export const CUSTOMER_LIST = "CUSTOMER_LIST";
