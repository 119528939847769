import { SET_SINGLE_SALE } from "store/types.js";

const initialState = {
  singleSales: [],
};

const saleRecord = (state = initialState, action) => {
  switch (action.type) {
    case SET_SINGLE_SALE:
      return {
        ...state,
        singleSales: action.singleSales,
      };
    default:
      return state;
  }
};

export default saleRecord;
