import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { connect } from "react-redux";
import { getSaleRecords, getShops, getSingleSales } from "store/actions";
import { useHistory } from "react-router";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const SaleRecords = ({
  saleRecord,
  getSaleRecords,
  shop,
  getShops,
  getSingleSales,
}) => {
  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const [filterSaleRecords, setFilterSaleRecords] = React.useState(
    saleRecord.saleRecords
  );

  const history = useHistory();

  React.useEffect(() => {
    const fetchData = async () => {
      await getShops();
      await getSaleRecords();
    };

    fetchData();
    return () => {
      fetchData();
    };
  }, [getShops, getSaleRecords]);

  const handleSelectShop = (shopID) => {
    const filterSaleRecordsByShopID = saleRecord.saleRecords.filter(
      (saleRecord) => saleRecord.shop.id === parseInt(shopID)
    );
    setFilterSaleRecords(filterSaleRecordsByShopID);
  };

  const handleSingleSales = async (id) => {
    await getSingleSales(parseInt(id));
    history.push("/admin/single-sales");
  };

  return (
    <>
      <SimpleHeader name="Sale Record List" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Your's Sale Record List</h3>
                <p className="text-sm mb-0">Sale Records</p>
              </CardHeader>
              <ToolkitProvider
                data={
                  filterSaleRecords.length === 0
                    ? saleRecord.saleRecords
                    : filterSaleRecords
                }
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "ID",
                    sort: true,
                  },
                  {
                    dataField: "shop.name",
                    text: "Shop",
                    sort: true,
                  },
                  {
                    dataField: "customer_name",
                    text: "Customer Name",
                    sort: true,
                  },
                  {
                    dataField: "whole_total",
                    text: "Whole Total",
                    sort: true,
                  },
                  {
                    dataField: "created_at",
                    text: "Created At",
                    sort: true,
                  },
                  {
                    text: "Actions",
                    dataField: "detail",
                    isDummyField: true,
                    formatter: (_, row) => (
                      <Button
                        color="info"
                        type="button"
                        onClick={() => handleSingleSales(row.id)}
                        size="sm"
                      >
                        Detail
                      </Button>
                    ),
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={12} sm={6}>
                          <div className="custom-control-inline">
                            <label
                              htmlFor="shops"
                              style={{ marginRight: "5px", marginTop: "3px" }}
                            >
                              Shops:
                            </label>
                            <Input
                              id="shops"
                              type="select"
                              className="form-control-sm"
                              onChange={(event) =>
                                handleSelectShop(event.target.value)
                              }
                            >
                              <option value="all">All</option>
                              {shop.shops.map((data) => (
                                <option value={data.id} key={data.id}>
                                  {data.name}
                                </option>
                              ))}
                            </Input>
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  saleRecord: store.saleRecord,
  shop: store.shop,
});

export default connect(mapStateToProps, {
  getSaleRecords,
  getShops,
  getSingleSales,
})(SaleRecords);
