import { combineReducers } from "redux";
import error from "store/reducers/error.js";
import auth from "store/reducers/auth.js";
import loading from "store/reducers/loading.js";
import shop from "store/reducers/shop.js";
import item from "store/reducers/item.js";
import priceTrack from "store/reducers/priceTrack.js";
import saleRecord from "store/reducers/saleRecord.js";
import singleSale from "store/reducers/singleSale.js";
import accountRec from "store/reducers/accountRec.js";
import itemTransfer from "store/reducers/itemTransfer.js";
import customer from "store/reducers/customer.js";

const reducers = combineReducers({
  error,
  auth,
  status: loading,
  shop,
  item,
  priceTrack,
  saleRecord,
  singleSale,
  accountRec,
  itemTransfer,
  customer,
});

export default reducers;
