import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import { connect } from "react-redux";
import {
  getShops,
  getItems,
  getPriceTracks,
  getSaleRecords,
  getAccountRecs,
  getItemTransfers,
  getCustomers,
} from "store/actions";

const CardsHeader = ({
  name,
  parentName,
  getShops,
  shop,
  getItems,
  item,
  getPriceTracks,
  priceTrack,
  getSaleRecords,
  saleRecord,
  getAccountRecs,
  accountRec,
  getItemTransfers,
  itemTransfer,
  getCustomers,
  customer,
}) => {
  React.useEffect(() => {
    const fetchData = async () => {
      await getShops();
      await getItems();
      await getPriceTracks();
      await getSaleRecords();
      await getAccountRecs();
      await getItemTransfers();
      await getCustomers();
    };

    fetchData();
    return () => {
      fetchData();
    };
  }, [getShops, getItems, getPriceTracks, getSaleRecords, getAccountRecs, getItemTransfers, getCustomers]);

  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Shops
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {shop.shops.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="ni ni-active-40" />
                        </div>
                      </Col>
                    </Row>
                    <a href="/admin/shops" className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">Shops</span>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Items
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {item.items.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-chart-pie-35" />
                        </div>
                      </Col>
                    </Row>
                    <a href="/admin/items" className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">Items</span>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Price Tracks
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {priceTrack.priceTracks.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <a href="/admin/price-tracks" className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">Price Tracks</span>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Sale Records
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {saleRecord.saleRecords.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                          <i className="ni ni-chart-bar-32" />
                        </div>
                      </Col>
                    </Row>
                    <a href="/admin/sale-records" className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">Sale Records</span>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Account Recs
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {accountRec.accountRecs.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-chart-pie-35" />
                        </div>
                      </Col>
                    </Row>
                    <a href="/admin/account-recs" className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">Account Recs</span>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Item Transfers
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {itemTransfer.itemTransfers.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                    <a href="/admin/item-transfers" className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">Item Transfers</span>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Customers
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {customer.customers.length}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                          <i className="ni ni-chart-bar-32" />
                        </div>
                      </Col>
                    </Row>
                    <a href="/admin/customers" className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">Customers</span>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

const mapStateToProps = (store) => ({
  status: store.status,
  shop: store.shop,
  item: store.item,
  priceTrack: store.priceTrack,
  saleRecord: store.saleRecord,
  accountRec: store.accountRec,
  itemTransfer: store.itemTransfer,
  customer: store.customer,
});

export default connect(mapStateToProps, {
  getShops,
  getItems,
  getPriceTracks,
  getSaleRecords,
  getAccountRecs,
  getItemTransfers,
  getCustomers,
})(CardsHeader);
