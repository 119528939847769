import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Button,
  UncontrolledAlert,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import axios from "axios";
import { host } from "services/api";

const ItemImport = () => {
  const [file, setFile] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const handleImport = async () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", file);
    
    // Request made to the backend api
    // Send formData object
    const response = await axios.post(`${host}/items-import`, formData);
    
    if (response.status === 200) {
      setMessage(response.data.data.message);
    } else {
      setMessage("Failed! Please Try Again.");
    }
  };

  return (
    <>
      <SimpleHeader name="Importing New Item" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3 className="mb-0">Import New Item</h3>
            {message && (
              <UncontrolledAlert color="warning">
                <span className="alert-icon">
                  <i className="ni ni-like-2" />
                </span>
                <span className="alert-text ml-1">{message}</span>
              </UncontrolledAlert>
            )}
          </CardHeader>
          <CardBody>
            <Form encType="multipart/form-data">
              <FormGroup>
                <label className="form-control-label" htmlFor="file">
                  Excel File
                </label>
                <Input
                  id="file"
                  placeholder="Choose Excel File"
                  type="file"
                  name="file"
                  onChange={(event) => setFile(event.target.files[0])}
                />
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="success"
                  type="button"
                  onClick={handleImport}
                >
                  Import
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default ItemImport;
