import React from "react";
// reactstrap components
import {
  UncontrolledAlert,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Button,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { connect } from "react-redux";
import { getShops, createItem, clearAlert } from "store/actions";
import store from "store";

const ItemCreate = ({ getShops, shop, createItem, item }) => {
  const [data, setData] = React.useState({
    code: "",
    name: "",
    category: "",
    buy_price: "",
    sale_price: "",
    left_item: "",
    shop_id: "",
  });

  React.useEffect(() => {
    store.dispatch(clearAlert());
    const fetchData = async () => {
      await getShops();
    };
    fetchData();
    return () => {
      fetchData();
    };
  }, [getShops]);

  const handleItemCreate = async () => {
    await createItem(data);
    setData({
      code: "",
      name: "",
      category: "",
      buy_price: "",
      sale_price: "",
      left_item: "",
      shop_id: "",
    });
  };

  return (
    <>
      <SimpleHeader name="Creating New Item" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3 className="mb-0">New Item</h3>
            {Object.keys(item.error).length > 0 &&
              Object.keys(item.error).map(function (keyName) {
                return (
                  <UncontrolledAlert color="warning" key={keyName}>
                    <span className="alert-icon">
                      <i className="ni ni-like-2" />
                    </span>
                    <span className="alert-text ml-1">
                      <strong>Warning!</strong> {item.error[keyName]}
                    </span>
                  </UncontrolledAlert>
                );
              })}
            {item.isSuccess && (
              <UncontrolledAlert color="success">
                <span className="alert-icon">
                  <i className="ni ni-like-2" />
                </span>
                <span className="alert-text ml-1">
                  <strong>Success !</strong> Item was created successfully.
                </span>
              </UncontrolledAlert>
            )}
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <label className="form-control-label" htmlFor="code">
                  Code
                </label>
                <Input
                  id="code"
                  placeholder="Enter Item Code"
                  type="text"
                  value={data.code || ""}
                  onChange={(event) =>
                    setData({
                      ...data,
                      code: event.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="name">
                  Name
                </label>
                <Input
                  id="name"
                  placeholder="Enter Item Name"
                  type="text"
                  value={data.name || ""}
                  onChange={(event) =>
                    setData({
                      ...data,
                      name: event.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="category">
                  Category
                </label>
                <Input
                  id="category"
                  placeholder="Enter Item Category"
                  type="text"
                  value={data.category || ""}
                  onChange={(event) =>
                    setData({
                      ...data,
                      category: event.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="buy_price">
                  Buy Price
                </label>
                <Input
                  id="buy_price"
                  placeholder="Enter Buy Price"
                  type="number"
                  value={data.buy_price || ""}
                  onChange={(event) =>
                    setData({
                      ...data,
                      buy_price: event.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="sale_price">
                  Sale Price
                </label>
                <Input
                  id="sale_price"
                  placeholder="Enter Sale Price"
                  type="number"
                  value={data.sale_price || ""}
                  onChange={(event) =>
                    setData({
                      ...data,
                      sale_price: event.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="left_item">
                  Left Item
                </label>
                <Input
                  id="left_item"
                  placeholder="Enter Left Item"
                  type="number"
                  value={data.left_item || ""}
                  onChange={(event) =>
                    setData({
                      ...data,
                      left_item: event.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="shops">
                  Shops
                </label>
                <Input
                  id="shops"
                  type="select"
                  value={data.shop_id || ""}
                  onChange={(event) =>
                    setData({
                      ...data,
                      shop_id: event.target.value,
                    })
                  }
                >
                  <option value="">Select Shop</option>
                  {shop.shops.map((data) => (
                    <option value={data.id} key={data.id}>
                      {data.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="success"
                  type="button"
                  onClick={handleItemCreate}
                  disabled={item.isLoading}
                >
                  {item.isLoading ? "Loading" : "Creating New Item"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
const mapStateToProps = (store) => ({
  item: store.item,
  shop: store.shop,
});

export default connect(mapStateToProps, { getShops, createItem })(ItemCreate);
