import {
  ITEM_LIST,
  CREATE_ITEM,
  STATUS_ITEM,
  ERROR_ITEM,
  IS_SUCCESS_ITEM,
  EDIT_ITEM,GET_ITEM,
  CLEAR_ALERT
} from "store/types.js";

const initialState = {
  items: [],
  item: {},
  isSuccess: false,
  isLoading: false,
  error: {},
};

const item = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ITEM:
      return {
        ...state,
        items: [action.item, ...state.items],
        isSuccess: true,
        isLoading: false,
        error: {},
      };
    case EDIT_ITEM:
      let index = state.items.findIndex((item) => item.id === action.item.id);
      state.items[index] = action.item;
      if (state.item.id === action.item.id) {
        state.item = action.item;
      }
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        error: {},
      }
    case GET_ITEM:
      return {
        ...state,
        item: action.item,
        isLoading: false,
        error: {},
      }
    case ITEM_LIST:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        error: {},
      };
    case STATUS_ITEM:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case IS_SUCCESS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
      };
    case ERROR_ITEM:
      return {
        ...state,
        error: action.error,
        isSuccess: false,
      };
    case CLEAR_ALERT:
      return {
        ...state,
        error: {},
        isSuccess: false,
      }
    default:
      return state;
  }
};

export default item;
