import { ITEM_TRANSFER_LIST } from "store/types.js";

const initialState = {
  itemTransfers: [],
};

const itemTransfer = (state = initialState, action) => {
  switch (action.type) {
    case ITEM_TRANSFER_LIST:
      return {
        ...state,
        itemTransfers: action.itemTransfers,
      };
    default:
      return state;
  }
};

export default itemTransfer;
