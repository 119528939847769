import { ACCOUNT_REC_LIST } from "store/types.js";
import { addError, removeError } from "store/actions/error.js";
import { setCurrentUser } from "store/actions/auth";
import { setLoading } from "store/actions/loading.js";
import { call } from "services/api.js";

export const setAccountRecList = (accountRecs) => ({
  type: ACCOUNT_REC_LIST,
  accountRecs,
});

export const getAccountRecs = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const response = await call("get", "account-recs");
      const accountRecs = response.data;

      dispatch(setAccountRecList(accountRecs));
      dispatch(removeError());
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setCurrentUser({}));
        localStorage.removeItem("jwtToken");
        dispatch(addError(error.response.data.data));
      } else {
        dispatch(addError(error.response.data));
      }
    }
    dispatch(setLoading());
  };
};
